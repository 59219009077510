<template>
  <v-app-bar 
    app
    fixed
    height="75"
    color="#fff"
    elevation="0"
    style="border-bottom: 1px solid #ddd"
  >
    <v-toolbar-title>
      <v-btn 
        icon
        small
        class="mr-2 hidden-md-and-up"
        @click="$emit('drawer')"
      >
        <v-icon small>mdi-menu</v-icon>
      </v-btn>
<!-- 
      <v-btn 
        icon
        small
      >
        <v-icon small>
          mdi-magnify
        </v-icon>
      </v-btn> -->
    </v-toolbar-title>

    <v-spacer></v-spacer>

    <!-- <v-btn 
      icon
      small
    >
      <v-icon small>
        mdi-apps
      </v-icon>
    </v-btn> -->

    <v-avatar
      size="36"
      color="primary"
      class="mx-2"
    >
      <span class="white--text body-1">
        {{ user.name.slice(0, 2) }}
      </span>
    </v-avatar>

    <v-btn 
      icon
      small
      class="mr-2"
      color="red"
      @click="logout()"
    >
      <v-icon small>
        mdi-power-standby
      </v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
export default {
  computed: {
    client () {
      return vault.take('client')
    },

    user () {
      return vault.take('user')
    }
  },

  methods: {
    logout () {
      vault.seal()
      location.href = '/auth/login' 
    }
  }
}
</script>